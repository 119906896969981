import axios from '@/common/axios'
import qs from 'qs'

export function addOrderCheck (data) {
  return axios({
    method: 'post',
    url: '/storage/orderCheck/add',
    data: qs.stringify(data)
  })
}

export function deleteOrderCheck (id) {
  return axios({
    method: 'delete',
    url: '/storage/orderCheck/delete/' + id
  })
}

export function updateOrderCheck (data) {
  return axios({
    method: 'put',
    url: '/storage/orderCheck/update',
    data: qs.stringify(data)
  })
}

export function selectOrderCheckInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/orderCheck/info/' + id
  })
}

export function selectOrderCheckList (query) {
  return axios({
    method: 'get',
    url: '/storage/orderCheck/list',
    params: query
  })
}

export function reviewOrderCheck (data) {
  return axios({
    method: 'put',
    url: '/storage/orderCheck/review',
    data: qs.stringify(data)
  })
}

export function inspectOrderCheck (data) {
  return axios({
    method: 'put',
    url: '/storage/orderCheck/inspect',
    data: qs.stringify(data)
  })
}
