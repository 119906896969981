import axios from '@/common/axios'
import qs from 'qs'

export function addOrderIn (data) {
  return axios({
    method: 'post',
    url: '/universal/orderIn/add',
    data: qs.stringify(data)
  })
}

export function deleteOrderIn (id) {
  return axios({
    method: 'delete',
    url: '/universal/orderIn/delete/' + id
  })
}

export function updateOrderIn (data) {
  return axios({
    method: 'put',
    url: '/universal/orderIn/update',
    data: qs.stringify(data)
  })
}

export function selectOrderInInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/orderIn/info/' + id
  })
}

export function selectOrderInList (query) {
  return axios({
    method: 'get',
    url: '/universal/orderIn/list',
    params: query
  })
}

export function reviewOrderIn (data) {
  return axios({
    method: 'put',
    url: '/universal/orderIn/review',
    data: qs.stringify(data)
  })
}

export function confirmOrderIn (data) {
  return axios({
    method: 'put',
    url: '/universal/orderIn/confirm',
    data: qs.stringify(data)
  })
}

export function selectOrderInByNo (serialNumber) {
  return axios({
    method: 'get',
    url: '/universal/orderIn/selectByNo/' + serialNumber
  })
}
