<template>
  <div id="orderCheck">
    <el-dialog
      :title="orderCheckFormTitle"
      width="1200px"
      :visible.sync="orderCheckDialogVisible"
      :close-on-click-modal="false"
      @close="orderCheckDialogClose"
    >
      <el-form
        ref="orderCheckFormRef"
        :model="orderCheckForm"
        :rules="orderCheckFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="内外销" prop="type">
              <el-radio-group
                v-model="orderCheckForm.type"
                :disabled="
                  orderCheckFormTitle !== '新增请验单' &&
                    orderCheckFormTitle !== '修改请验单' &&
                    orderCheckFormTitle !== '请验单详情'
                "
              >
                <el-radio
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.code"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库单编号" prop="orderInNo">
              <el-input
                v-model="orderCheckForm.orderInNo"
                placeholder="请输入入库单编号"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-success"
                  @click="selectOrderIn"
                />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="orderCheckForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="
                  orderCheckFormTitle !== '新增请验单' &&
                    orderCheckFormTitle !== '修改请验单' &&
                    orderCheckFormTitle !== '请验单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker
                v-model="orderCheckForm.checkDate"
                placeholder="请选择请验日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  orderCheckFormTitle !== '新增请验单' &&
                    orderCheckFormTitle !== '修改请验单' &&
                    orderCheckFormTitle !== '请验单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="请验单">
          <vxe-toolbar
            v-if="
              orderCheckFormTitle === '新增请验单' ||
                orderCheckFormTitle === '修改请验单'
            "
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column
              field="productName"
              title="产品名称"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="batchNo"
              title="批号"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="reason"
              title="请验原因"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="
                orderCheckFormTitle === '新增请验单' ||
                  orderCheckFormTitle === '修改请验单'
              "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="orderCheckForm.reviewerResult"
                :disabled="
                  orderCheckFormTitle !== '审核请验单' &&
                    orderCheckFormTitle !== '请验单详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="接检结果" prop="inspectorResult">
              <el-radio-group
                v-model="orderCheckForm.inspectorResult"
                :disabled="
                  orderCheckFormTitle !== '接检请验单' &&
                    orderCheckFormTitle !== '请验单详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="orderCheckDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="orderCheckFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input
          v-model="searchForm.serialNumber"
          placeholder="请输入编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="内外销" prop="type">
        <el-select v-model="searchForm.type" placeholder="请选择内外销" clearable>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ORDER_CHECK_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="orderCheckPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{
            scope.row.checkDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="checker" label="请验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkerDate">{{
            scope.row.checkerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{
            scope.row.reviewerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspector" label="接检人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectorDate">{{
            scope.row.inspectorDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="接检结果">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectorResult === 1">通过</span>
          <span v-if="scope.row.inspectorResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="内外销">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">内销</span>
          <span v-if="scope.row.type === 2">外销</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['ORDER_CHECK_DELETE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['ORDER_CHECK_UPDATE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['ORDER_CHECK_REVIEW']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="
              checkPermission(['ORDER_CHECK_INSPECT']) && scope.row.status === 2
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleInspect(scope.$index, scope.row)"
          >
            接检
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="orderCheckPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOrderCheck,
  deleteOrderCheck,
  updateOrderCheck,
  selectOrderCheckInfo,
  selectOrderCheckList,
  reviewOrderCheck,
  inspectOrderCheck
} from '@/api/storage/orderCheck'
import moment from 'moment'
import { selectOrderInByNo } from '@/api/universal/orderIn'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      orderCheckDialogVisible: false,
      orderCheckFormTitle: '',
      orderCheckForm: {
        id: '',
        serialNumber: '',
        checkDate: '',
        reviewerResult: '',
        inspectorResult: '',
        detailJson: '',
        taskId: '',
        type: '',
        orderInNo: ''
      },
      orderCheckFormRules: {
        serialNumber: [
          {
            required: true,
            message: '编号不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      orderCheckPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: '',
        type: ''
      },
      detailList: [],
      typeList: []
    }
  },
  created () {
    selectOrderCheckList(this.searchForm).then((res) => {
      this.orderCheckPage = res
    })
    selectDictList(2).then(res => {
      this.typeList = res
    })
  },
  methods: {
    orderCheckDialogClose () {
      this.$refs.orderCheckFormRef.resetFields()
      this.detailList = []
    },
    orderCheckFormSubmit () {
      if (this.orderCheckFormTitle === '请验单详情') {
        this.orderCheckDialogVisible = false
        return
      }
      this.$refs.orderCheckFormRef.validate(async (valid) => {
        if (valid) {
          if (this.orderCheckFormTitle === '新增请验单') {
            this.orderCheckForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addOrderCheck(this.orderCheckForm)
          } else if (this.orderCheckFormTitle === '修改请验单') {
            this.orderCheckForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateOrderCheck(this.orderCheckForm)
          } else if (this.orderCheckFormTitle === '审核请验单') {
            console.log(this.orderCheckForm.reviewerResult === null)
            console.log(this.orderCheckForm.inspectorResult)
            if (this.orderCheckForm.reviewerResult != null) {
              await reviewOrderCheck(this.orderCheckForm)
            } else {
              this.$message({
                message: '请选择审核结果！',
                type: 'warning'
              })
            }
          } else if (this.orderCheckFormTitle === '接检请验单') {
            if (this.orderCheckForm.inspectorResult != null) {
              await inspectOrderCheck(this.orderCheckForm)
            } else {
              this.$message({
                message: '请选择接检结果！',
                type: 'warning'
              })
            }
          }
          this.orderCheckPage = await selectOrderCheckList(this.searchForm)
          this.orderCheckDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.orderCheckFormTitle = '新增请验单'
      this.orderCheckDialogVisible = true
      this.orderCheckForm.serialNumber
        = 'QY' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOrderCheck(row.id)
        if (
          this.orderCheckPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.orderCheckPage = await selectOrderCheckList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.orderCheckFormTitle = '修改请验单'
      this.orderCheckDialogVisible = true
      this.selectOrderCheckInfoById(row.id)
    },
    handleReview (index, row) {
      this.orderCheckFormTitle = '审核请验单'
      this.orderCheckDialogVisible = true
      this.selectOrderCheckInfoById(row.id)
    },
    handleInspect (index, row) {
      this.orderCheckFormTitle = '接检请验单'
      this.orderCheckDialogVisible = true
      this.selectOrderCheckInfoById(row.id)
    },
    handleInfo (index, row) {
      this.orderCheckFormTitle = '请验单详情'
      this.orderCheckDialogVisible = true
      this.selectOrderCheckInfoById(row.id)
    },
    selectOrderCheckInfoById (id) {
      selectOrderCheckInfo(id).then((res) => {
        this.orderCheckForm.id = res.id
        this.orderCheckForm.serialNumber = res.serialNumber
        this.orderCheckForm.checkDate = res.checkDate
        this.orderCheckForm.reviewerResult = res.reviewerResult
        this.orderCheckForm.inspectorResult = res.inspectorResult
        this.orderCheckForm.taskId = res.taskId
        this.orderCheckForm.type = res.type
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOrderCheckList(this.searchForm).then((res) => {
        this.orderCheckPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOrderCheckList(this.searchForm).then((res) => {
        this.orderCheckPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOrderCheckList(this.searchForm).then((res) => {
        this.orderCheckPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ORDER_CHECK_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (
        this.checkPermission(['ORDER_CHECK_INSPECT'])
        && row.status === 2
      ) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    selectOrderIn () {
      if (this.orderCheckForm.orderInNo) {
        this.detailList = []
        selectOrderInByNo(this.orderCheckForm.orderInNo).then((res) => {
          for (let value of res.detailList) {
            this.detailList.push({
              productName: value.materialName,
              spec: value.spec,
              batchNo: value.batchNo,
              quantity: value.quantity
            })
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
