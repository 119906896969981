import axios from '@/common/axios'
import qs from 'qs'

export function addDict (data) {
  return axios({
    method: 'post',
    url: '/dict/add',
    data: qs.stringify(data)
  })
}

export function deleteDict (id) {
  return axios({
    method: 'delete',
    url: '/dict/delete/' + id
  })
}

export function updateDict (data) {
  return axios({
    method: 'put',
    url: '/dict/update',
    data: qs.stringify(data)
  })
}

export function selectDictInfo (id) {
  return axios({
    method: 'get',
    url: '/dict/info/' + id
  })
}

export function selectDictTree () {
  return axios({
    method: 'get',
    url: '/dict/tree'
  })
}

export function selectDictList (pcode) {
  return axios({
    method: 'get',
    url: '/dict/list/' + pcode
  })
}
